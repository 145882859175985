<template>
    <nav class="main-menu">
        <div class="container">
            <ul class="list float-right">
                <li class="logo-area default">
                    <router-link to="/">
                        <img src="/assets/img/logo/logo.png" />
                    </router-link>
                </li>
                <li class="list-item list-item-has-children mega-menu mega-menu-col-3">
                    <router-link tag="a" class="nav-link" to="/">Home</router-link>
                </li>
                <li class="list-item list-item-has-children mega-menu mega-menu-col-3">
                    <router-link class="nav-link" to="/AboutUs">About Us</router-link>
                </li>
                <li class="list-item list-item-has-children mega-menu mega-menu-col-3">
                    <a class="nav-link" href="/">Products</a>
                    <ul class="sub-menu nav" style="min-height:290px">
                        <li v-for="group in groups" class="list-item list-item-has-children">
                            <ul class="sub-menu nav">
                                <li class="list-item">
                                    <a class="nav-link group-nav-link" style="font-size:14px;"><b>{{ group.Title }}</b></a>
                                </li>
                                <li v-for="subGroup in group.SubGroups" class="list-item subgroup-nav">
                                    <router-link tag="a" class="nav-link subgroup-nav-title" :to="{name: 'filter' , query:{ SubGroupId: subGroup.Id , SubGroupName: subGroup.Title.replace(/\s+/g, '-')  }}">
                                        {{ subGroup.Title }}
                                    </router-link>

                                </li>
                            </ul>
                        </li>
                        <!--<img v-if="group.GroupPicPath != null" :src="group.GroupPicPath" alt="" @error="imageLoadOnError" />-->
                    </ul>
                </li>
                <li class="list-item list-item-has-children mega-menu mega-menu-col-3">
                    <router-link class="nav-link" to="/ContactUs">Contact Us</router-link>
                </li>
                <li class="list-item list-item-has-children mega-menu" style="width:650px;">
                    <search-box></search-box>
                </li>
                <li class="list-item list-item-has-children mega-menu" style="margin-left:50px;">
                    <router-link class="dk-btn-info" to="/verification">
                        Verification Check
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
    import searchBox from '../menuComponents/searchBox.vue';
    export default {
        computed: {
            groups: function () {
                return this.$store.getters.readGroups;
            }
        }, 
        components: {
            'search-box': searchBox,
        },
        methods: {
            imageLoadOnError(event) {
                event.target.src = "/assets/img/no-image-available.png";
            },
        }
    }
</script>

<style scoped>
 .main-menu .list .list-item {
        display: inline-block;
    }
    .main-menu .list > .list-item > a::before {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #f49b00;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    .subgroup-nav-title:hover {
        color: #145e95 !important;
        transition: all .3s;
    }
    .subgroup-nav-title {
        color: #979797 !important;
        font-weight: bold !important;
        font-size: 13px !important;
        transition: all .3s;
    }
    .group-nav-link {
        color: #f49b00;
        font-weight: bolder !important;
        font-size: 14px !important;
    }
    header.main-header .main-menu {
        width: 100%;
        float: right;
        margin-top: 0;
        border-bottom:unset;
    }
    .list-item a{
    color: #957777;
    }
  
        header.main-header .main-menu .list > .list-item:hover > a {
            box-shadow: unset;
        }
    header.main-header .main-menu .list .list-item.mega-menu > .sub-menu {
        min-width: 230px;
        width: 230px;
    }
    .nav-link{
        cursor:pointer;
    }
</style>