export const httpPromise = function ( component , url , method, data , successCallback, errorCallback) {
   
    var baseUrl = 'http://localhost:54781';
    switch (method.toLocaleLowerCase().trim()) {
        //********************************************************************************************************************************
        case 'get':
            component.$http.get(baseUrl + url, { params: data, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } }).then(
                response => {
                    if (successCallback != null)
                        successCallback(response);
                },
                response => {
                    if (response.status == 401) {
                        localStorage.removeItem('accessToken');
                        component.$router.push('/login');
                    }
                    if (response.status == 400) {
                        if (errorCallback != null)
                            errorCallback(response);
                    } else {

                    }
                });
            break;
         //********************************************************************************************************************************
        case 'post':
            component.$http.post(baseUrl + url, data, { headers: { 'Content-Type': 'application/json' ,   'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } }).then(
                response => {
                    if (successCallback != null)
                        successCallback(response);
                },
                response => {
                    if (response.status == 401) {
                        localStorage.removeItem('accessToken');
                        component.$router.push('/login');
                    }
                    if (response.status == 400) {
                        if (errorCallback != null)
                            errorCallback(response);
                    } else {
                         
                    }
                });
            break;
         //********************************************************************************************************************************
        case 'delete':
            component.$http.delete(baseUrl + url, { params: data, headers: { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } }).then(
                response => {
                    if (successCallback != null)
                        successCallback(response);
                },
                response => {
                    if (response.status == 401) {
                        localStorage.removeItem('accessToken');
                        component.$router.push('/login');
                    }
                    if (response.status == 400) {
                        if (errorCallback != null)
                            errorCallback(response);
                    } else {

                    }
                });
            break;
        case 'put':
            component.$http.put(baseUrl + url, data, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') } }).then(
                response => {
                    if (successCallback != null)
                        successCallback(response);
                },
                response => {
                    if (response.status == 401) {
                        localStorage.removeItem('accessToken');
                        component.$router.push('/login');
                    }
                    if (response.status == 400) {
                        if (errorCallback != null)
                            errorCallback(response);
                    } else {

                    }
                });
            break;
    }
   
}