//======================================================
import Vue from 'vue';
import numeral from 'numeral';
Vue.use(numeral);
//======================================================
export const registerGlobalFilters = function () {
    Vue.filter("formatNumber", function (value) {
        return numeral(value).format("0,0"); 
    });
}
//======================================================

