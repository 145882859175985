<template>
    <div class="mg-top-10 all-nav row">
        <div class="col-12">
            <navbar-component></navbar-component>
        </div>
       
    </div>
</template>

<script>
    import searchBox from './searchBox.vue';
    import navbarComponent from './navbar.vue';
    export default {
        components: {
            'search-box': searchBox, 
            'navbar-component': navbarComponent , 
        }
    }
</script>

<style scoped>

</style>