<template>
    <div class="row" v-if="brands != ''">
        <div class="col-12 padding">
            <div class="brand-slider card">
                <header class="card-header">
                    <h3 class="card-title"><span>Special Brands</span></h3>
                </header>
                <div class="owl-carousel">
                    <div class="item" v-for="brand in brands">
                        <router-link :to="{name: 'filter' , query:{BrandCode: brand.Id , BrandName: brand.Title  } }">
                            <img :src="brand.LogoPath" @error="imageLoadOnError" :alt="brand.Alt">
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            brands: function () {
                return this.$store.getters.readBrands;
            }
        },
        methods: {
            imageLoadOnError(event) {
                event.target.src = "/assets/img/no-image-available.png";
            },
        }
    }
</script>

<style scoped>
    .padding {
        padding-left:0;
        padding-right:0;
    }
    .brand-slider {
        border-radius: 0px;
    }
</style>