<template>
    <div>
        <!--<div v-if="isShowLoader" class="wrapper-child">
            <img src="/assets/img/loader/loaderImageHome.svg" />
            <p>Please wait...</p>
        </div>-->
        <div class="wrapper-parent">
            <responsive-header-component></responsive-header-component>
            <div class="wrapper default">
                <header class="main-header default">
                    <div class="container">
                        <header-component></header-component>
                    </div>
                    <menu-component></menu-component>
                </header>
                <main class="content-main default">
                    <div style="background-color:#fff" class="container">
                        <div class="content-main no-padding-right no-padding-left   col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="page-title div-img-header">
                                    <div class="container">
                                        <div class="row">
                                            <div class="div-title-main col col-xs-12">
                                                <h2>Verification</h2>
                                                <ol class="breadcrumb">
                                                    <li><router-link to="/">Home</router-link></li>
                                                    <li>Verification</li>
                                                </ol>
                                            </div>
                                        </div> <!-- end row -->
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="div-content pb-20">
                                        <div class="row">
                                            <div class="col-xs-12 col-lg-12 col-md-12">
                                                <h3 class="text-header">Check your product with product number and box printing code </h3>
                                            </div>
                                            <div class="section-about col-xs-12 col-lg-7 col-md-12">
                                                <h1>Check your product</h1>
                                                <p>
                                                    Check the accuracy of your product by entering the verification codes on the product. All products have their own verification codes and also include Lot Number, Exp Date and Product Code data. Do not use a dash (-). Do not use lower case.
                                                </p>
                                                <p>
                                                    If you encounter any problem, please email us about the problem we are encountering in the communication. you can receive the image on the error page and forward it to us. If your products can not be verified, please send us the product code numbers via your email.<br /> <span class="email-as">E-mail : info@santos-pharma.com</span>
                                                </p>

                                            </div>
                                            <div class="pt-60 col-lg-5 col-lg-5">
                                                <div class="form-ckeck">
                                                    <form @submit.prevent="sendCode" class="form-account">
                                                        <div class="form-account-title">Product VerificationCode (Sensitive to uppercase and lowercase letters)</div>
                                                        <div class="form-account-row">
                                                            <label class="input-label">
                                                                <i class="now-ui-icons ui-1_lock-circle-open"></i>
                                                            </label>
                                                            <input name="VerificationCode" type="text" data-vv-as="VerificationCode" v-validate="{required: true}" v-model="VerificationCode" placeholder="VerificationCode" class="input-field" aria-required="false" aria-invalid="false">
                                                            <span class="error">{{ errors.first('VerificationCode') }}</span>
                                                        </div>
                                                        <div class="btn-div">
                                                            <div class="div-loader">
                                                                <button type="submit" class="dk-btn dk-btn-info"> Check Values </button>
                                                                <div :class="{'loader-main':showLoaderBtn}">
                                                                    <img class="imgloader" src="/assets/img/loader/loader.svg" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                                <div v-if="productInfo.ProductId == null" class="alarm-text-reject">
                                                    <span v-text="productInfo.StateMessage"></span>
                                                </div>
                                                <div v-else class="alarm-text-ok">
                                                    <span v-text="productInfo.StateMessage"></span>
                                                </div>
                                            </div>
                                            <div v-if="productInfo.ProductId != null" class="div-image-content col-lg-12 col-lg-12">
                                                <div class="row">
                                                    <div class="product-image-ok  col-lg-12 col-lg-12">
                                                        <div class="img-pro">
                                                            <img @error="imageLoadOnError" class="img-fluid" :src="productInfo.PicPath" :alt="productInfo.ProductName" :title="productInfo.ProductName" />
                                                        </div>
                                                        <div class="img-title">
                                                            <h3>{{productInfo.ProductName}}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <footer-component></footer-component>
            </div>
        </div>

    </div>
</template>

<script>
    import { httpPromise } from "../../module/httpUtility";
    import { initialSite } from "../../module/initialSite";
    import specialBrands from '../homeComponents/specialBrands.vue';
    export default {
        data() {
            return {
                isShowLoader: false, VerificationCode: '', productInfo: {}, showLoaderBtn:false
            }
        },
        components: {
            'special-brands': specialBrands,
        },
        beforeMount: function () {
            initialSite(this);
        },
        methods: {
            sendCode() {
                this.$validator.validateAll().then(valid => {
                    if (valid) {
                        this.showLoaderBtn = true;
                        httpPromise(this, '/v1/ProductManagement/ProductVerificationCodes/Find', 'get', { VerificationCode: this.VerificationCode },
                            response => {
                                this.productInfo = response.data.Value;
                                this.showLoaderBtn = false;
                            },
                            response => {
                                toastr.remove();
                                toastr.error(response.data.Message);
                                this.isShowLoader = false;
                                this.showLoaderBtn = false;
                            });
                    }
                });
            },
            imageLoadOnError(event) {
                event.target.src = "/assets/img/no-image-available.png";
            }
        }
    }
</script>

<style scoped>
    .input-field {
        font-family:Arial !important;
    }
    .div-loader {
        display: inline-block;
        position: relative;
    }

        .div-loader .loader-main {
            position: absolute;
            background-color: #eedfdf;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
        }

            .div-loader .loader-main img {
                width: 40px;
            }

    .error {
        color: red;
        font-size: 13px;
        padding-top: 10px;
        display: inline-block;
    }

    .input-field {
        letter-spacing: 1.3px;
    }

    .div-image-content {
        padding-top: 15px;
        border-top: 2px solid #f90;
    }

    .product-image-ok .img-title {
        margin-top: 15px;
    }

        .product-image-ok .img-title h3 {
            font-size: 18px;
            font-weight: bold;
            color: #666;
        }

    .product-image-ok .img-pro {
        max-width: 350px;
    }

    .product-image-ok {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

        .product-image-ok img {
            border: 2px solid #ddd;
            border-radius: 9px;
            overflow: hidden;
            display: block;
            height: auto;
            margin: auto;
            max-width: 100%;
            width: auto !important;
        }

    .form-account-title {
        font-size: 16px;
    }

    .text-header {
        text-align: center;
        color: #f49b00;
        font-weight: bold;
    }

    .alarm-text-reject, .alarm-text-ok {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
    }

    .alarm-text-ok {
        color: green;
    }

    .alarm-text-reject {
        color: red;
    }

    .dk-btn-info {
        overflow: hidden;
    }

    .dk-btn {
        font-size: 17px;
        padding: 11px 25px;
        margin-top:0;
    }

    .form-ckeck {
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .form-ckeck .form-account {
            width: 55%;
        }

    .email-as {
        font-weight: bold;
    }

    @media only screen and (min-width: 640px) and (max-width: 1077px) {
        .content-main {
            margin-top: 50px;
        }

        .input-field {
            padding: 11px 11px 11px 40px;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .text-header {
            font-size: 18px;
        }

        .product-image-ok .img-title h3 {
            font-size: 1.3em;
            font-weight: bold;
        }

        .alarm-text-reject, .alarm-text-ok {
            font-size: 14px;
        }

        .content-main {
            margin-top: 45px;
        }
    }

    .card {
        box-shadow: unset;
    }

    .wrapper-child {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .pt-60 {
        padding-top: 60px;
    }

    .div-title-main {
        padding-left: 150px;
    }

    .div-img-header {
        background: url("/assets/img/header/page-title.jpg");
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        height: 260px;
        position: relative;
        width: 100%;
    }

    .page-title .breadcrumb {
        text-align: center;
    }

    .page-title .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    .page-title ol {
        color: #fff;
    }

    .page-title h2 {
        font-size: 48px;
        font-size: 3.2rem;
        font-weight: 800;
        margin: 1em 0 0.23em;
    }

    .page-title h2 {
        color: #fff;
    }

    .page-title .breadcrumb li a {
        color: #fff;
    }

    .page-title .breadcrumb li {
        font-size: 18px;
        font-size: 1.2rem;
        color: #fdc900;
        margin-right: 5px;
    }

    .page-title::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .page-title .breadcrumb > li + li::before {
        font-family: "FontAwesome";
        content: "\f105";
        color: #fff;
        margin-right: 5px;
    }


    .section-about h1 {
        font-size: 30px;
    }

    .page-title .breadcrumb li a:hover {
        color: #fdc900;
    }

    .pb-20 {
        padding-bottom: 100px;
    }

    .offer-pic::after {
        content: "";
        width: 80%;
        height: 118%;
        border: 2px solid #fdc900;
        position: absolute;
        left: 150px;
        top: -22px;
    }

    .offer-pic {
        padding: 40px 45px 0 0;
        position: relative;
    }

    ::after, ::before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .offer-pic img {
        position: relative;
        z-index: 2;
    }



    .m-wt {
        max-height: 250px;
    }

        .m-wt img {
            height: 100%;
        }

    .mt-15 {
        margin-top: 15px;
    }

    .tab-pane {
        min-height: 230px;
    }

    .hr {
        margin-top: 45px;
        margin-bottom: 30px;
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/

    .content-main .content {
        width: 100%;
        margin: 20px 12%;
        padding: 20px;
    }

        .content-main .content .title {
            font-size: 20px;
            padding-left: 20px;
            padding-top: 20px;
        }

        .content-main .content .div-content p {
            line-height: 2;
            font-size: 16px;
        }

    .param-p {
        text-align: justify;
        line-height: 2;
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .pb-20 {
            padding-bottom: 10px;
        }

        .box-tabs > ul {
            padding-left: 20px;
        }

        .section-about h1 {
            font-size: 19px;
            font-weight: bold;
        }

        .div-title-main {
            padding-left: 20px;
        }

        .param-title span, .param-title {
            font-size: 14px;
        }

        .param-p {
            font-size: 12px;
        }

        .content-main .content {
            margin: 40px 0%;
        }

            .content-main .content .title {
                font-size: 17px;
            }

            .content-main .content .div-content p {
                line-height: 2;
                font-size: 14px;
            }
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
</style>