<template>
    <div class="content-main no-padding-right no-padding-left   col-md-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="page-title div-img-header">
                <div class="container">
                    <div class="row">
                        <div class="div-title-main col col-xs-12">
                            <h2>About Us</h2>
                            <ol class="breadcrumb">
                                <li><router-link to="/">Home</router-link></li>
                                <li>About Us</li>
                            </ol>
                        </div>
                    </div> <!-- end row -->
                </div>
            </div>
            <div class="content">
                <div class="div-content pb-20">
                    <div class="row">
                        <div class="section-about col-xs-12 col-lg-5 col-md-12">
                            <h1>SANTOS PHARMA is a pharmaceutical company founded in 2020.</h1>
                            <p>We have the standard of the European Union. The mission of the company is to help people live longer, be healthier and happier. In order to achieve these objectives, the company uses the latest results of researches in pharmaceutical area, produces and provides high quality medicines for the prevention and treatment of various diseases.</p>
                            <p>SANTOS PHARMA has set as its main objective, to produce only quality medicines, which also would have an acceptable price, based on the purchasing power of the ordinary citizen. And by important donations, offered to medical institutions the company represents a model of corporate social responsibility, alternatively contributing to the achievement of the task.</p>
                        </div>
                        <div class="pt-60 col-lg-7 col-xs-12">
                            <div class="offer-pic">
                                <img src="/assets/img/inline-page/offer-pic.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        beforeMount: function () {
        }
    }
</script>

<style scoped>
    .pt-60 {
        padding-top: 60px;
    }

    .div-title-main {
        padding-left: 150px;
    }

    .div-img-header {
        background: url("/assets/img/header/page-title.jpg");
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        height: 400px;
        position: relative;
        width: 100%;
    }

    .page-title .breadcrumb {
        text-align: center;
    }

    .page-title .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    .page-title ol {
        color: #fff;
    }

    .page-title h2 {
        font-size: 48px;
        font-size: 3.2rem;
        font-weight: 800;
        margin: 1em 0 0.23em;
    }

    .page-title h2 {
        color: #fff;
    }

    .page-title .breadcrumb li a {
        color: #fff;
    }

    .page-title .breadcrumb li {
        font-size: 18px;
        font-size: 1.2rem;
        color: #fdc900;
        margin-right: 5px;
    }

    .page-title::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .page-title .breadcrumb > li + li::before {
        font-family: "FontAwesome";
        content: "\f105";
        color: #fff;
        margin-right: 5px;
    }


    .section-about h1 {
        font-size: 30px;
    }

    .page-title .breadcrumb li a:hover {
        color: #fdc900;
    }

    .pb-20 {
        padding-bottom: 100px;
    }

    .offer-pic {
        padding: 40px 45px 0 0;
        position: relative;
    }


    .offer-pic img {
        position: relative;
        z-index: 2;
    }



    .m-wt {
        max-height: 250px;
    }

        .m-wt img {
            height: 100%;
        }

    .mt-15 {
        margin-top: 15px;
    }

    .tab-pane {
        min-height: 230px;
    }

    .hr {
        margin-top: 45px;
        margin-bottom: 30px;
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/

    .content-main .content {
        width: 100%;
        margin: 20px 12%;
        padding: 20px;
    }

        .content-main .content .title {
            font-size: 20px;
            padding-left: 20px;
            padding-top: 20px;
        }

        .content-main .content .div-content p {
       
            line-height: 2;
            font-size: 15px;
        }
    /***ooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo*/
    .box-tabs-tab a {
        padding: 14px 20px;
        position: relative;
        color: #6f6f6f;
        display: block;
        line-height: 27px;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .none-border::after {
        border-bottom: unset !important;
    }

    .box-tabs-tab::after {
        content: '';
        width: 80%;
        border-bottom: 2px dashed #ddd;
        position: absolute;
        top: 50%;
        right: -87px;
    }

    .box-tabs-tab {
        border-right: unset;
        position: relative;
        margin-right: 50px;
        margin-left: 50px;
    }

    .box-tabs > ul {
        list-style: none;
        border: unset;
        background-color: unset;
        font-size: 17px;
        font-size: 1.214rem;
        line-height: 1.294;
        letter-spacing: -.4px;
        width: 100%;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .box-tabs-tab .active::before {
        display: block;
        content: unset;
        right: -1px;
        left: -1px;
        bottom: 100%;
        height: 4px;
        background: #00bfd6;
        position: absolute;
    }

    .box-tabs-tab a {
        border: 1px solid #f49b00;
    }

        .box-tabs-tab a.active {
            background-color: #f49b00;
            color: #fff;
            border: 1px solid #f49b00;
            margin-left: -1px;
        }

    .param-p {
     
        line-height: 2;
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .pt-60 {
            padding-top: 10px;
        }
        .offer-pic {
            padding: 0px 0px 0px 0px !important;
        }
            .offer-pic img {
                width:100%;
            }
        .pb-20 {
            padding-bottom: 10px;
        }

        .box-tabs > ul {
            padding-left: 20px;
        }

        .section-about h1 {
            font-size: 19px;
            font-weight:bold;
        }

        .div-title-main {
            padding-left: 20px;
        }

        .param-title span, .param-title {
            font-size: 14px;
        }

        .param-p {
            font-size: 12px;
        }

        .content-main .content {
            margin: 40px 0%;
        }

            .content-main .content .title {
                font-size: 17px;
            }

            .content-main .content .div-content p {
            
                line-height: 2;
                font-size: 14px;
            }
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
</style>