<template>
    <div class="footer-widgets">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
                <div class="widget-menu widget card">
                    <header class="card-header">
                        <h3 class="card-title">SANTOS PHARMA</h3>
                    </header>
                    <router-link to="/">
                        <img src="/assets/img/logo/logo-footer.png" style="height:300px;" />
                    </router-link>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
                <div class=" widget-menu widget card">
                    <header class="card-header">
                        <h3 class="card-title">Menu</h3>
                    </header>
                    <ul class="footer-menu">
                        <li>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>
                            <router-link to="/aboutUs">About Us</router-link>
                        </li>
                        <li>
                            <i class="fa fa-caret-right" aria-hidden="true"></i>
                            <router-link to="/contactUs">Contact Us </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5">
                <div class="widget-menu widget card" v-if="baseInfo != null">
                    <header class="card-header">
                        <h3 class="card-title">Contact Information</h3>
                    </header>
                    <ul class="footer-menu">
                        <li>
                            <span class="title"></span>
                            <a href="#">Made in EU</a>
                        </li>
                        <li>
                            <span class="title">Email : </span>
                            <a class="w-text-h" href="mailto:info@santos-pharma.com">
                                <span class="w-text-value mail">info[@]santos-pharma[dot]com</span>
                            </a>
                        </li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        computed: {
            baseInfo: function () {
                return this.$store.getters.readBaseInfo;
            }
        }
    }
</script>

<style scoped>
    .footer-social a .fa {
        padding-right: 3px !important;
    }

    .footer-social a {
        padding-right: 10px;
    }

    .text-center {
        text-align: center;
    }

    .footer-menu li span.title {
        color: #fff;
        font-weight: bold;
        font-size: 17px;
    }

    .footer-menu li .fa {
        font-size: 18px;
        vertical-align: middle;
        padding-right: 8px;
        color: #000;
    }

    .footer-menu li a {
        color: #fff;
        font-size: 18px;
    }

    .text-p {
        color: #000;
        font-size: 16px;
        text-justify: auto;
        line-height: 1.9;
    }

    .div-image {
        width: 16px;
        height: 16px;
        display: inline-block;
    }

    .img-aparat {
        width: 16px;
        border-radius: 10px;
        margin-bottom: 8px;
    }

    footer.main-footer .footer-widgets .socials .footer-social {
        width: 100%;
        background-color: #c67672;
    }


        footer.main-footer .footer-widgets .socials .footer-social a.margin {
            margin: 0 25px;
        }

    @media only screen and (min-width: 991px) and (max-width: 1165px) {
        footer.main-footer .footer-widgets .socials .footer-social a.margin {
        }

        footer.main-footer .footer-widgets .socials .footer-social a.a-block {
            display: block !important;
        }

        footer.main-footer .footer-widgets .socials .footer-social {
            height: 100%;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .footer-menu li .fa {
            font-size: 16px;
        }

        .footer-menu li span.title {
            font-size: 15px;
        }

        .footer-menu li a {
            font-size: 14px;
        }

        .text-center {
            text-align: right !important;
        }
    }
</style>