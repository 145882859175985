<template>
    <div class="div-home">
        <!--<div v-if="showLoader" class="wrapper-child">
            <img src="/assets/img/loader/loaderImageHome.svg" />
            <p>please wait...</p>
        </div>-->
        <div class="wrapper-parent">

            <responsive-header-component></responsive-header-component>
            <!-- responsive-header -->

            <div class="wrapper default">

                <!-- header -->
                <header class="main-header default">
                    <!--<headerTop-component></headerTop-component>-->
                    <!-- responsive-header -->
                    <div class="container">
                        <header-component></header-component>
                    </div>
                    <menu-component></menu-component>
                </header>
                <!-- header -->


                <main class="main default">
                    <div class="container">
                        <div class="row">
                            <div class="slider-main col-12 col-lg-12 order-1 order-lg-2">
                                <sliders></sliders>
                            </div>
                        </div>
                        <div class="row">
                            <div class="company-main col-12 col-lg-12 order-1 order-lg-2">
                                <widget-product :sectionNumber="0"></widget-product>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12 col-md-12 col-lg-4 " v-if="banners != null && banners[0] != null && banners[0].IsActive == true && banners[0].PicPath != null">
                                <a target="_blank" :href="banners[0].URL" :title="banners[0].Alt">
                                    <img class="img-fluid" :src="banners[0].PicPath" :alt="banners[0].Alt" :title="banners[0].Alt">
                                </a>
                                <h2 class="btn-redirect post-title" style="text-align:center; margin-top:10px;">
                                    <a :alt="banners[0].Alt">{{ banners[0].Alt }} </a>
                                </h2>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-4 " v-if="banners != null && banners[1] != null && banners[1].IsActive == true && banners[1].PicPath != null">
                                <a target="_blank" :href="banners[1].URL" :title="banners[1].Alt">
                                    <img class="img-fluid" :src="banners[1].PicPath" :alt="banners[1].Alt" :title="banners[1].Alt">
                                </a>
                                <h2 class="btn-redirect post-title" style="text-align:center; margin-top:10px;">
                                    <a :alt="banners[1].Alt">{{ banners[0].Alt }} </a>
                                </h2>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-4 " v-if="banners != null && banners[2] != null && banners[2].IsActive == true && banners[2].PicPath != null">
                                <a target="_blank" :href="banners[2].URL" :title="banners[2].Alt">
                                    <img class="img-fluid" :src="banners[2].PicPath" :alt="banners[2].Alt" :title="banners[2].Alt">
                                </a>
                                <h2 class="btn-redirect post-title" style="text-align:center; margin-top:10px;">
                                    <a :alt="banners[2].Alt">{{ banners[0].Alt }} </a>
                                </h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="advantages-main col-12 col-lg-12 order-1 order-lg-2">
                                <advantages-detail></advantages-detail>
                            </div>
                        </div>
                        <div class="row">
                            <div class="pro-main no-padding-right no-padding-left col-12 col-lg-12 order-1 order-lg-2">
                                <our-company></our-company>
                            </div>
                        </div>
                        <div class="row">
                            <div class="advantages-main col-12 col-lg-12 order-1 order-lg-2">
                                <advantages-detail-2></advantages-detail-2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-12 order-1 order-lg-2">
                                <!--<verification-Product></verification-Product>-->
                                <div class="widget-banner card" v-if="banners != null && banners[3] != null && banners[3].IsActive == true && banners[3].PicPath != null">
                                    <a target="_blank" :href="banners[3].URL" :title="banners[2].Alt">
                                        <img class="img-fluid" :src="banners[3].PicPath" :alt="banners[3].Alt" :title="banners[3].Alt">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="company-main col-12 col-lg-12 order-1 order-lg-2">
                                <widget-product :sectionNumber="1"></widget-product>
                            </div>
                        </div>
                        <!--<special-brands></special-brands>-->
                    </div>
                </main>
                <footer-component></footer-component>
            </div>
        </div>


        
    </div>
</template>

<script>
    import sliders from './sliders.vue';
    import verificationProduct from './verificationProduct.vue';
    import ourCompany from './ourCompany.vue';
    import advantagesDetail from './advantagesDetail.vue';
    import advantagesDetail2 from './advantagesDetail2.vue';
    import advantagesCompany from './advantagesCompany.vue';
    import widgetProduct from './widgetProduct.vue';
    import { initialSite } from "../../module/initialSite";
 

    export default {
        data() {
            return {
                banner: 0,
                showLoader: false,
            }
        },
        components: {
            'sliders': sliders, 
            'widget-product': widgetProduct,
            'our-company': ourCompany,
            'advantages-company': advantagesCompany, 
            'advantages-detail': advantagesDetail,
            'advantages-detail-2': advantagesDetail2,
            'verification-Product': verificationProduct
        },
        computed: {
            banners: function () {
                return this.$store.getters.readBanners;
            }
        },
        beforeMount: function () {
            initialSite(this);
        }
    }
</script>

<style scoped>
    .verification-main {
        padding: 10px 0 10px 0;
        background-color: #1fc8db;
        background-image: linear-gradient(141deg, #9fb8ad 0%,#2cb5e8 70%,#1fc8db 51% );
    }
    .tp-bging-main {
        position: relative;
    }

    .pro-main {
        background-color: #fff;
    }

    .slider-main {
        background-color: #fff;
    }

    .tp-bging-main, .company-main, .slider-main {
        padding-left: 0;
        padding-right: 0;
    }

    .logo-main-navbar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mg-top-10 {
        background: #f2f2f2;
    }

    .all-nav {
        background: #f2f2f2;
        border-bottom: 2px solid #e8e8e8;
    }

    header.main-header .logo-area a img {
        height: 50px;
        float: right;
        margin-top: 2px;
    }
    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .verification-main {
            padding: 20px 0 30px 0;
        }
    }
    @media only screen and (min-width:650px) and (max-width: 850px) {
        .verification-main {
            padding: 20px 0 30px 0;
        }
    }
    @media only screen and (min-width:851px) and (max-width: 991px) {
        .verification-main {
            padding: 20px 0 30px 0;
        }
    }
</style>