import home from '../components/homeComponents/home.vue';
import filter from '../components/filterComponent/filter.vue';
import ContactUs from '../components/ContactUsComponent/ContactUs.vue';
import content from '../components/contentComponents/content.vue';
import product from '../components/productComponents/product.vue';
import webServiceError from '../components/errorComponents/webServiceError.vue';
import notFound from '../components/errorComponents/notFound.vue';
import aboutUs from '../components/contentComponents/aboutUs.vue';
import faq from '../components/contentComponents/faq.vue';
import pharmaceutical from '../components/contentComponents/pharmaceutical.vue';
import verification from '../components/verificationComponent/verification.vue';
export const routes = [

    { path: '/', component: home },
    { name: 'filter' , path: '/filter', component: filter },
    { path: '/ContactUs', component: ContactUs },
    { name: 'verification', path: '/verification', component: verification },
    { name: 'product', path: '/product/:productId/:productName', component: product },
    { path: '/webServiceError', component: webServiceError },
    {
        name: 'content', path: '/content', component: content , 
        children: [
            { name: 'aboutUs', path: '/aboutUs', component: aboutUs },
            { name: 'pharmaceutical', path: '/pharmaceutical', component: pharmaceutical },
            { name: 'faq', path: '/faq', component: faq },

            //{ name: 'changePassword', path: '/changePassword', component: changePassword },
            //{ name: 'addresses', path: '/addresses', component: addresses },
        ]
    },
    { path: '*', component: notFound },
]




