<template>
    <div class="search-nav search-area default">
        <form action="" class="search">
            <div class="div-search">
                <input class="searchbox" @keyup="search" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" v-model="searchValue" type="text" placeholder="Product Search...">
                <div v-show="isShowRemoveSerchbtn"> <i @click="removeSearchValue" class="now-ui-icons ui-1_simple-remove btn-remove-search"></i></div>
            </div>
            <button @click="redirectToFilter" type="button"><img src="/assets/img/search.png" alt=""></button>
            <div class="c-search__results js-search-results is-active" v-show="isShowSearchResult   && !(searchValue == null || searchValue == '')" v-if="searchResult.SubGroups != null && searchResult.Tags != null && searchResult.SubGroups != null">
                <ul v-if="searchResult.SubGroups.length == 0 && searchResult.Products.length == 0 &&  searchResult.Tags.length == 0" class="js-autosuggest-empty-list c-search__results-list">
                    <li>
                        <a class="js-search-keyword-link">
                            <span class="c-search__result-item">No Results Found </span>
                            <span class="c-search__result-item--category js-search-keyword"></span>
                        </a>
                    </li>
                </ul>
                <div v-else class="c-search__results-footer">
                    <ul class="c-search">
                        <li v-for="subGroup in searchResult.SubGroups"><a @click="filterBySubGroup(subGroup.Id , subGroup.Title.replace(/\s+/g, '-'))">{{ searchValue }} in subgroup <span class="ctaegoryname">{{subGroup.Title}}</span></a></li>
                    </ul>
                    <hr v-if="searchResult.Products.length != 0" />
                    <ul class="c-search">
                        <li v-for="product in searchResult.Products"><a @click="filterBySearch(product.Title)">{{product.Title}}</a></li>
                    </ul>
                    <hr v-if="searchResult.Tags.length != 0 " />
                    <ul class="c-search__results-trends cearchtags">
                        <li v-for="tag in searchResult.Tags"><a @click="filterByTag(tag.Id , tag.Title.replace(/\s+/g, '-'))">{{tag.Title}}</a></li>
                    </ul>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import { httpPromise } from "../../../module/httpUtility";
    $(function () {

    });
    export default {

        data() {
            return {
                searchValue: '',
                searchResult: {},
                isShowSearchResult: true,
                isShowRemoveSerchbtn: false,
                isShowSppiner: false,
            }
        },
        watch: {
            searchValue: function (newValue, oldValue) {
                if (IsNullOrEmptyString(newValue)) {
                    this.isShowSearchResult = false;
                    this.isShowRemoveSerchbtn = false;
                }
                else {
                    this.isShowSearchResult = true;
                    this.isShowRemoveSerchbtn = true;
                }
            },
            '$route': function (from, to) {
                this.searchValue = this.$route.query.SearchValue == null ? '' : this.$route.query.SearchValue;
            }
        },
        methods: {
            onClick() {
                this.isShowSearchResult = false;
                this.isShowRemoveSerchbtn = false;
            },
            removeSearchValue() {
                this.searchValue = '';
            },
            search() {
                this.isShowSppiner = true;
                if (!IsNullOrEmptyString(this.searchValue)) {
                    httpPromise(this, '/v1/ProductManagement/Search/Read?SearchValue=' + this.searchValue, 'get', '',
                        response => {
                            toastr.remove();
                            this.searchResult = response.data.Value;
                            this.isShowSearchResult = true;
                            this.isShowSppiner = false;
                        },
                        response => {
                            toastr.remove();
                            toastr.error(response.data.Message);
                            this.isShowSppiner = false;
                        });
                }
            },
            filterByTag(id, name) {
                this.$router.push({ name: 'filter', query: { TagId: id, TagName: name } });
                this.isShowSearchResult = false;
            },
            filterBySubGroup(id, name) {
                this.$router.push({ name: 'filter', query: { SubGroupId: id, SubGroupName: name } });
                this.isShowSearchResult = false;
            },
            filterBySearch(name) {
                this.$router.push({ name: 'filter', query: { SearchValue: name } });
                this.isShowSearchResult = false;
            },
            redirectToFilter() {
                if (!IsNullOrEmptyString(this.searchValue)) {
                    this.$router.push({ name: 'filter', query: { SearchValue: this.searchValue } })
                } else {
                    this.$router.push({ name: 'filter' })
                }
            }
        },
        beforeMount() {
            this.searchValue = this.$route.query.SearchValue == null ? '' : this.$route.query.SearchValue;
            document.addEventListener('click', this.onClick);
        }
    }
</script>

<style scoped>
    .searchbox {
        text-align: left;
        direction: ltr;
    }

    .search-nav {
        width: 90%;
        margin-right: 25px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    ul li a {
        cursor: pointer;
    }

    .btn-remove-search {
        z-index: 666;
    }

    .search-area ul.info-login {
        display: none;
    }

    @media only screen and (min-width:850px) and (max-width: 1077px) {
        ul.js-autosuggest-empty-list {
            width: 100% !important;
            float: right !important;
        }

            ul.js-autosuggest-empty-list li {
                float: left !important;
                line-height: 1.5 !important;
            }

        nav.header-responsive .search-nav {
            top: 8px;
        }

        .search-area form {
            position: relative;
            direction: rtl;
            float: right;
            width: 100%;
            height: 45px;
            bottom: 5px;
        }

        .search-area {
            position: absolute;
            top: 0px;
            height: 40px;
            width: 63%;
            right: 170px;
            bottom: 5px;
        }

            .search-area ul.info-login {
                display: block;
            }

            .search-area ul.info-login {
                font-size: 14px !important;
                position: relative;
                top: -43px;
                left: -95px;
            }

                .search-area ul.info-login li {
                    float: left;
                    text-align: right;
                }

        .search-nav ul.c-search {
            width: 100%;
            float: none !important;
        }

            .search-nav ul.c-search li {
                line-height: 1.5;
                text-align: left;
                float: none !important;
            }

            .search-nav ul.c-search a {
                font-size: 13px;
                color: #212121 !important;
                background: transparent;
            }

        .c-search__results ul.cearchtags li a {
            font-size: 13px !important;
            padding: 0 !important;
        }

        .c-search__results .cearchtags {
            width: 100% !important;
        }

        .c-search__results ul.cearchtags li {
            height: 26px;
            border-radius: 13px;
            background-color: #eceff1;
            display: flex;
            margin-top: 15px;
            margin-left: 15px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            float: right !important;
        }
    }

    @media only screen and (min-width:640px) and (max-width: 850px) {
        ul.js-autosuggest-empty-list {
            width: 100% !important;
            float: right !important;
        }

            ul.js-autosuggest-empty-list li {
                float: left !important;
                line-height: 1.5 !important;
            }

        nav.header-responsive .search-nav {
            top: 8px;
        }

        .search-area form {
            position: relative;
            direction: rtl;
            float: right;
            width: 100%;
            height: 45px;
            bottom: 5px;
        }

        .search-area {
            position: absolute;
            top: 0px;
            height: 40px;
            width: 63%;
            right: 170px;
            bottom: 5px;
        }


            .search-area ul.info-login {
                font-size: 14px !important;
                position: relative;
                top: -43px;
                left: -95px;
            }

                .search-area ul.info-login li {
                    float: left;
                    text-align: right;
                }

        .search-nav ul.c-search {
            width: 100%;
            float: none !important;
        }

            .search-nav ul.c-search li {
                line-height: 1.5;
                text-align: left;
                float: none !important;
            }

            .search-nav ul.c-search a {
                font-size: 13px;
                color: #212121 !important;
                background: transparent;
            }

        .c-search__results ul.cearchtags li a {
            font-size: 13px !important;
            padding: 0 !important;
        }

        .c-search__results .cearchtags {
            width: 100% !important;
        }

        .c-search__results ul.cearchtags li {
            height: 26px;
            border-radius: 13px;
            background-color: #eceff1;
            display: flex;
            margin-top: 15px;
            margin-left: 15px;
            padding: 8px;
            justify-content: center;
            align-items: center;
            float: right !important;
        }
    }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .navbar-translate {
            margin-top: 26px;
        }

        ul.js-autosuggest-empty-list {
            width: 100% !important;
            float: right !important;
        }

            ul.js-autosuggest-empty-list li {
                float: left !important;
                line-height: 1.5 !important;
            }

        .search-area {
            position: absolute;
            top: 50px;
            height: 40px;
            bottom: 5px;
        }

        nav.header-responsive .search-nav {
            left: 12px;
            top: 40px;
        }

        nav.header-responsive form input {
            padding: 4px 4px 4px 10px;
        }

        nav.header-responsive form button {
            width: 40px;
            height: 76%;
            background-color: #f49b00;
        }

        .search-area form {
            position: relative;
            direction: rtl;
            float: right;
            width: 100%;
            height: 45px;
            bottom: 5px;
        }

        .search-area ul.info-login {
            display: block;
        }

            .search-area ul.info-login li {
                float: left;
            }

        .search-nav ul {
            width: 100%;
        }

            .search-nav ul.c-search {
                width: 100%;
                float: none;
            }

                .search-nav ul.c-search li {
                    line-height: 1.5;
                }

                .search-nav ul.c-search a {
                    font-size: 13px;
                    color: #212121 !important;
                    background: transparent;
                }

        ul.c-search li {
            float: none !important;
            text-align: left !important;
        }

        .search-area ul.info-login {
            font-size: 14px !important;
            position: relative;
            top: -92px;
            left: 104px;
        }

        .c-search__results {
            padding: 0 3px;
        }

            .c-search__results .cearchtags {
                width: 100% !important;
            }

            .c-search__results ul.cearchtags li {
                height: 26px;
                border-radius: 13px;
                background-color: #eceff1;
                display: flex;
                margin-top: 15px;
                margin-left: 15px;
                padding: 8px;
                justify-content: center;
                align-items: center;
                float: right !important;
            }

                .c-search__results ul.cearchtags li a {
                    font-size: 13px !important;
                    padding: 0 !important;
                }
    }
</style>