export const requireAuthPages = [
    '/info',
    '/addresses',
    '/changePassword',
    '/credit',
    '/orders',
    '/profile',
    '/transactions',  
    '/chooseAddress', 
    '/invoice', 
    '/orderTracking' , 
]