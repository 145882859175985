<template>
    <section id="main-slider" class="carousel slide carousel-fade card" data-ride="carousel">
        <ol class="carousel-indicators">
            <li v-for="(slider,index) in sliders" data-target="#main-slider" :data-slide-to="index" :class="{active: (index == 0)? true: false}"></li>
        </ol>
        <div class="carousel-inner">
            <div v-for="(slider,index)  in sliders" :class="{'carousel-item' : true , active: (index == 0)? true: false }">
                <a class="d-block">
                    <img :src="slider.PicPath" @error="imageLoadOnError" class="d-block w-100" alt="" style="max-height:450px;">
                </a>
            </div>
            
        </div>
        <!--<a class="carousel-control-prev" href="#main-slider" role="button" data-slide="prev">
            <i class="now-ui-icons arrows-1_minimal-left"></i>
        </a>
        <a class="carousel-control-next" href="#main-slider" data-slide="next">
            <i class="now-ui-icons arrows-1_minimal-right"></i>
        </a>-->
    </section>
</template>

<script>
  
    export default {
        computed: {
            sliders: function () {
                return this.$store.getters.readSliders;
            }, 
           
        },
        methods: {
            imageLoadOnError(event) {
                event.target.src = "/assets/img/no-slider-image-available.png";
            },
        }
    }
</script>

<style scoped>
    .card {
        box-shadow:unset;
    }
    .content-main {
        overflow: hidden;
    }

        .content-main,
        .content-main .slideshow-main {
            padding: 0;
        }

    .carousel-control-next,.carousel-control-prev {
        position: absolute;
        top: 45%;
        bottom: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 45px;
        color: #fff;
        text-align: center;
        opacity: .5;
        height: 45px;
        border: 2px solid #fff !important;
        border-radius: 5px;
    }
    .carousel-control-prev {
        left:35px !important;
    }
    .carousel-control-next {
        right: 35px !important;
    }
    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .carousel-control-next, .carousel-control-prev {
            width: 35px;
            height: 35px;
        }

        .carousel .now-ui-icons {
            font-size: 1em;
        }
    }
</style>