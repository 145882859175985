<template>
    <div>
        <!-- responsive-header -->
   
        <!-- responsive-header -->

        <div class="wrapper default">

            <!-- header -->
      
            <!-- header -->
            <!-- main -->
            <main class="page-404">
                <div class="container text-center">
                    <div class="page-404-title">
                        <h1>WESERVICE ERROR</h1>
                    </div>
                    <div class="page-404-actions">
                        <a tag="a" href="/" class="page-404-action page-404-action--primary">تلاش مجدد</a>
                    </div>
                    <div class="page-404-image">
                        <img src="../../../public/assets/img/webServiceError.jpg">
                    </div>
                </div>
            </main>
            <!-- main -->
            <!-- footer -->
            <footer-component></footer-component>
            <!-- footer -->
        </div>
    </div>
</template>

<script>
    export default {
        beforeMount: function () {
            createPageTitle('Server Error');
        }
    }
</script>
<style scoped>
</style>