import Vue from 'vue';
import numeral from 'numeral';
Vue.use(numeral); 
import footer from "./components/sharedComponents/footerComponents/footer.vue"
import header from './components/sharedComponents/headerComponents/header.vue'
import headerTop from './components/sharedComponents/headerComponents/headerTop.vue'
import menu from './components/sharedComponents/menuComponents/menu.vue'
import responsiveHeader from './components/sharedComponents/headerComponents/responsiveHeader.vue'
import footerFixNav from './components/sharedComponents/footerComponents/footerFixNav.vue'



export const registerGlobalComponents = function () {
    Vue.component('footer-component', footer);
    Vue.component('header-component', header);
    Vue.component('headerTop-component', headerTop);
    Vue.component('menu-component', menu);
    Vue.component('responsive-header-component', responsiveHeader);
    Vue.component('footer-fixnav-component', footerFixNav);
}