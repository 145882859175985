<template>
    <div>
        <!--<div v-if="showLoader" class="wrapper-child">
            <img src="/assets/img/loader/loaderImageHome.svg" />
            <p>Please wait...</p>
        </div>-->
        <div>
            <!-- responsive-header -->
            <!--<headerTop-component></headerTop-component>-->
            <responsive-header-component></responsive-header-component>
            <!-- responsive-header -->

            <div class="wrapper default">

                <!-- header -->
                <header class="main-header default">
                    <div class="container">
                        <header-component></header-component>
                    </div>
                    <menu-component></menu-component>
                </header>
                <!-- header -->
                <!----------------------------------------------------------------------------------------->
                <!-- main -->
                <main class="content-main default">
                    <div style="background-color:#fff" class="container">
                        <div class="content-main no-padding-right no-padding-left col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="page-title div-img-header">
                                    <div class="container">
                                        <div class="row">
                                            <div class="div-title-main col col-xs-12">
                                                <h2>Contact Us</h2>
                                                <ol class="breadcrumb">
                                                    <li><a href="/">Home</a></li>
                                                    <li>Contact Us</li>
                                                </ol>
                                            </div>
                                        </div> <!-- end row -->
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="div-content">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-xs-12">
                                                <div :class="{ loader: !isShowSppiner }" style="position:absolute;background-color:#80808033;top:0;bottom:0;left:0;right:0;z-index:9999;width:100%;height:100%">
                                                    <img style="position:absolute;top:43%;left:43%;z-index:100" src="/assets/img/loader/loader.svg" />
                                                </div>
                                                <ul style="color:red;font-size:12px;text-align:center;list-style-type:circle;margin-top:10px;">
                                                    <li v-for="error in errors.all()">{{ error }}</li>
                                                </ul>
                                                <form @submit.prevent="sendMessage" class="form-account">
                                                    <div class="form-account-title">FullName</div>
                                                    <div class="form-account-row">
                                                        <label class="input-label">
                                                            <i class="now-ui-icons users_single-02"></i>
                                                        </label>
                                                        <input type="text" v-validate="{required: true , max:80  }" data-vv-as="FullName" v-model="MessageInfo.FullName" placeholder="Enter your FullName" class="input-field" aria-required="false" aria-invalid="false">
                                                    </div>
                                                    <div class="form-account-title">Phone Number</div>
                                                    <div class="form-account-row">
                                                        <label class="input-label">
                                                            <i class="now-ui-icons tech_mobile"></i>
                                                        </label>
                                                        <input type="text" v-validate="{ required: true, regex:/^0\d{10}$/ }" data-vv-as="Phone Number" v-model="MessageInfo.MobNo" placeholder="Enter your Phone Number" class="input-field " aria-required="false" aria-invalid="false">
                                                    </div>
                                                    <div class="form-account-title">Email</div>
                                                    <div class="form-account-row">
                                                        <label class="input-label">
                                                            <i class="now-ui-icons ui-1_email-85"></i>
                                                        </label>
                                                        <input type="text" v-validate="{required: true ,email:true }" data-vv-as="Email" v-model="MessageInfo.Email" placeholder="Enter your Email" class="input-field " aria-required="false" aria-invalid="false">
                                                    </div>
                                                    <div class="form-account-title">Message</div>
                                                    <div class="form-account-row">
                                                        <textarea rows="3" v-validate="{required: true , max:1000  }" data-vv-as="Message" v-model="MessageInfo.Comment" type="text" placeholder="Enter your Message" class="input-field input-textarea" aria-required="false" aria-invalid="false"></textarea>
                                                    </div>
                                                    <div class="btn-div">
                                                        <button type="submit" class="btn btn-info"> Send Message </button>
                                                    </div>
                                                </form>

                                            </div>

                                            <div class="col-lg-6 col-md-6 col-xs-12">
                                                <div class="iframe-div">
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2735107.8519979534!2d10.69622982748394!3d47.97498582934826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4796cbd8ce7c5a27%3A0xc780f6651f128538!2seu-LISA%20-%20European%20Agency%20for%20the%20operational%20management%20of%20Large-Scale%20IT%20Systems%20in%20the%20area%20of%20freedom%2C%20security%20and%20justice!5e0!3m2!1sen!2s!4v1627045354128!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
                <!-- main -->
                <!------------------------------------------------------------------------------------------>
                <footer-component></footer-component>
            </div>
        </div>

    </div>
</template>

<script>
    import { initialSite } from "../../module/initialSite";
    import { httpPromise } from "../../module/httpUtility";
    export default {
        data() {
            return {
                MessageInfo: {},
                isShowSppiner: false,
                showLoader: false,
            }
        },
        beforeMount: function () {
            initialSite(this);
        },
        methods: {
            sendMessage() {
                this.$validator.validateAll().then(valid => {
                    if (valid) {
                        this.isShowSppiner = true;
                        httpPromise(this, '/v1/Systems/ContactUs/AddMessage', 'post', JSON.stringify(this.MessageInfo),
                            response => {
                                toastr.remove();
                                toastr.success(response.data.Message);
                                this.MessageInfo = {};
                                this.isShowSppiner = false;
                            },
                            response => {
                                toastr.remove();
                                toastr.error(response.data.Message);
                                this.MessageInfo = {};
                                this.isShowSppiner = false;
                            });
                    }
                });
            }
        },
        components: {
        }
    }
</script>

<style type="text/css">
    .iframe-div {
        height: 415px;
        margin-bottom: 30px;
        margin-top: 30px;
    }

        .iframe-div iframe {
            width: 100%;
            height: 100%;
        }
    /*title image*/
    .div-title-main {
        padding-left: 150px;
    }

    .div-img-header {
        background: url("/assets/img/header/page-title.jpg");
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;
        height: 400px;
        position: relative;
        width: 100%;
    }

    .page-title .breadcrumb {
        text-align: center;
    }

    .page-title .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    .page-title ol {
        color: #fff;
    }

    .page-title h2 {
        font-size: 48px;
        font-size: 3.2rem;
        font-weight: 800;
        margin: 1em 0 0.23em;
    }

    .page-title h2 {
        color: #fff;
    }

    .page-title .breadcrumb li a {
        color: #fff;
    }

    .page-title .breadcrumb li {
        font-size: 18px;
        font-size: 1.2rem;
        color: #fdc900;
        margin-right: 5px;
    }

    .page-title::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    input {
        font-family: Arial;
    }

    .page-title .breadcrumb > li + li::before {
        font-family: "FontAwesome";
        content: "\f105";
        color: #fff;
        margin-right: 5px;
    }

    .page-title .breadcrumb li a:hover {
        color: #fdc900;
    }
    /*title image*/

    .address-text p {
        color: #777;
        font-size: 14px;
        padding-left: 7px;
    }

    .address-title i {
        font-size: 20px;
        color: cadetblue;
        vertical-align: baseline;
        padding-right: 5px;
    }

    .address-title span {
        font-size: 18px;
        font-weight: bold;
        color: #555;
    }

    .btn-info {
        background-color: #2CA8FF;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 17px;
        padding: 8px 35px;
    }

    .btn-div {
        text-align: center;
        margin-bottom: 30px;
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
    .input-textarea {
        padding: 11px 11px 11px 15px;
    }

    .content-main .content {
        width: 100%;
        margin: 20px 12%;
        padding: 20px;
    }

        .content-main .content .title {
            font-size: 20px;
            padding-left: 20px;
            padding-top: 20px;
        }

        .content-main .content .div-content p {
            /*text-align: justify;*/
            line-height: 2;
            font-size: 15px;
        }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        main.content-main {
            margin-top: 45px;
        }

        .div-title-main {
            padding-left: 20px;
        }

        .address-title span {
            font-size: 16px;
        }

        .content-main .content {
            margin: 40px 0%;
        }

            .content-main .content .title {
                font-size: 17px;
            }

            .content-main .content .div-content p {
                text-align: justify;
                line-height: 2;
                font-size: 12px;
            }
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
</style>