<template>
    <nav class="navbar direction-ltr fixed-top header-responsive">
        <div class="container">
            <div class="navbar-translate">
                <router-link class="navbar-brand" to="/">
                    <img src="/assets/img/logo/logo2.jpeg" height="24px" alt="">
                </router-link>
                <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                        data-target="#navigation" aria-controls="navigation-index" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span class="navbar-toggler-bar bar1"></span>
                    <span class="navbar-toggler-bar bar2"></span>
                    <span class="navbar-toggler-bar bar3"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse justify-content-end" id="navigation">
                <div class="logo-nav-res default text-center">
                    <router-link to="/">
                        <img src="/assets/img/logo/logo2.jpeg" height="36px" alt="">
                    </router-link>
                </div>
                <hr />
                <ul class="navbar-nav default">
                    <li @click="closeNav">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li @click="closeNav">
                        <router-link to="/aboutus">About Us</router-link>
                    </li>
                    <li v-for="group in groups" class="sub-menu">
                        <a>{{group.Title}}</a>
                        <ul>
                            <li v-for="subGruop in group.SubGroups" @click="closeNav">
                                <router-link :to="{ name: 'filter' , query: { SubGroupId: subGruop.Id , SubGroupName: subGruop.Title.replace(/\s+/g, '-') }}">{{subGruop.Title}}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li @click="closeNav">
                        <router-link to="/contactus">Contact Us</router-link>
                    </li>
                    <li @click="closeNav">
                        <router-link to="/verification">Verification Check</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <div style="max-width:200px;">
            <search-box></search-box>
        </div>
    </nav>
</template>

<script>
 
    import searchBox from "../menuComponents/searchBox.vue"
    export default {
        components: {
            'search-box': searchBox
        },
        computed: {
            groups: function () {
                return this.$store.getters.readGroups;
            }
        },
        methods: {
            removeSearchValue() {
                this.searchValue = '';
            },
            closeNav() {
                $(function () {
                    $('#bodyClick').click();
                }); 
            }
        },
    }
</script>

<style scoped>
    .dd-value {
        padding-right:2px;
    }
    .text-k-r {
        direction: ltr;
    }
    .w-text-value {
        display: inline-block;
    }
    .right-join {
        text-align:center;
        /*padding-right: 60px;*/
    }
    .text-k-r .fa {
        vertical-align: baseline;
        padding-right: 4px;
        font-size: 17px;
        color: #f49b00;
        transition: all .3s;
    }
    .text-k-r {
        padding: 2px;
        font-size: 14px;
        color:#666;
    }
    .w-text-value {
        font-weight:bold;
    }
    .right-join {
        direction: rtl;
    }
    .subgroup-line-bottom {
    }
    .subgroup-title {
        color: #979797 !important;
        font-weight: bold !important;
        font-size: 13px;
    }
    .gruop-title {
        font-weight: bold !important;
        color: #145e95 !important;
        font-size: 14px;
    }
    .category-title {
        color: #f49b00 !important;
        font-weight: bold !important;
        font-size: 15px;
    }
    .line-bottom, .subgroup-line-bottom {
        border-bottom: 1px solid #ddd;
    }
    .sidebar-collapse .navbar-collapse {
        right:unset;
        left:0;
    }
        @media only screen and (min-width:640px) and (max-width: 850px) {
        }

        @media only screen and (min-width: 0px) and (max-width: 640px) {
            .right-join[data-v-56ecae49] {
                margin-bottom: 5px;
            }


            .text-k-r .fa {
                font-size: 16px;
            }

            .text-k-r {
                font-size: 13px;
            }

            nav.header-responsive form {
                position: relative;
                direction: rtl;
                float: right;
                height: 40px;
                width: 100%;
            }

            nav.header-responsive .search-nav ul.info-login {
                float: none;
                text-align: left;
            }

            nav.header-responsive .search-nav ul {
                width: 100%;
            }

                nav.header-responsive .search-nav ul.info-login {
                    font-size: 10px !important;
                    position: relative;
                    top: -87px;
                    left: -70px;
                }

                nav.header-responsive .search-nav ul i {
                    font-size: 18px;
                }

            .js-search-keyword-link .c-search__result-item {
                font-size: 12px;
            }

            .c-search__results {
                left: 0px;
                max-height: 300px;
                overflow-y: scroll;
                padding: 0
            }

            nav.header-responsive .search-nav ul {
                overflow: auto;
            }

                nav.header-responsive .search-nav ul li {
                    float: right;
                }

            nav.header-responsive .search-nav .cearchtags li a {
                display: inline;
            }

            nav.header-responsive .search-nav ul li a {
                font-size: 12px;
                color: #212121 !important;
                background: transparent;
                text-align: right;
                display: inline-block;
            }

            hr {
                display: inline-block;
                width: 100%;
            }

            .c-search__results ul li {
                line-height: 1.4;
            }

            .c-search ul li a {
                text-align: right;
            }
        }
</style>