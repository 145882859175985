<template>
    <div class="info" v-if="baseInfo != null">
        <div class="row">
            <div class="text-right-a col-12 col-lg-4">
                <span class="title">Address : </span> <span class="text-all">{{baseInfo.Address}}</span>
            </div>
            <div class="text-right-a col-xs-12 col-lg-2">
                <span class="title">Phone : </span> <span class="text-all">{{ baseInfo.TellNo }}</span>
            </div>
            <div class="text-right-a  col-xs-12 col-lg-3">
                <span class="title">Email: </span><a class="email-text" href="#"></a>
            </div>
            <div class="col-12 col-lg-3">
                <div class="socials">
                    <div class="footer-social">
                        <a href="http://instagram.com/" target="_blank"><i class="fa fa-instagram"></i>. اینستاگرام</a>
                        <a href="http://t.me/" class="margin" target="_blank"><i class="fa fa-telegram"></i>. تلگرام</a>
                        <a href="" class="margin" target="_blank"><i class="fa fa-whatsapp"></i>. واتساپ</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            baseInfo: function () {
                return this.$store.getters.readBaseInfo;
            }
        }
    }
</script>

<style scoped>
    .margin {
        margin:0 5px 0 5px;
    }
    .div-image {
        display: inline-block;
    }
    .text-all {
        font-size: 14px;
        font-weight: bold;
    }
    .title {
        color: #c2ead5;
    }
    .fa,.email-text, .text-all {
        color: #777777;
    }
    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .footer-social {
            padding: 3px;
            font-size: 14px;
        }
        .title {
            font-size: 15px;
        }
        .text-right-a {
            text-align:right;
        }
        .text-all, .email-text {
            font-size: 13px;
        }
    }
</style>