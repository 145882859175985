import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex); 
export const store = new Vuex.Store({
    state: {
        //==============================================  
        groups: [],
        sliders: [],
        promoSectionProducts: [],
        brands: [],
        homePageContents: null,
        baseInfo: null,
        banners: [],
    },

    getters: {
        //============================================== 
        readGroups: state => {
            return state.groups;
        },
        readSliders: state => {
            return state.sliders;
        },
        readPromoSectionProducts: state => {
            return state.promoSectionProducts;
        },
        readHomePageContents: state => {
            return state.homePageContents;
        },
        readBrands: state => {
            return state.brands;
        },
        readBaseInfo: state => {
            return state.baseInfo;
        },
        readBanners: state => {
            return state.banners;
        },
    },
    mutations: {
        //============================================== 
        setHomePageContents: (state, data) => {
            state.homePageContents = data;
            state.groups = data.Groups;
            state.baseInfo = data.BaseInfos;
            state.sliders = data.Sliders;
            state.promoSectionProducts = data.PromoSectionProducts;
            state.banners = data.Banners;
        },
        //==============================================
    }
});