<template>
    <div class="content-main no-padding-right no-padding-left  col-md-12 col-sm-12 col-xs-12">
        <div class="row">
            <div class="page-title div-img-header">
                <div class="container">
                    <div class="row">
                        <div class="div-title-main col col-xs-12">
                            <h2>FAQ </h2>
                            <ol class="breadcrumb">
                                <li><a href="/">Home</a></li>
                                <li>FAQ</li>
                            </ol>
                        </div>
                    </div> <!-- end row -->
                </div>
            </div>
            <div class="content">

                <div class="div-content">
                    <h3 class="div-content-h3">Frequently Asked Questions</h3>
                    <p>

                    </p>
                </div>
                <hr />
                <div class="div-content">
                    <h3 class="div-content-h3"></h3>
                    <p>
                        <span class="content-title">Where Are You Based in? </span>
                        <ul>
                            <li>Hello our lab is in Germany. </li>
                        </ul>
                    </p>
                </div>
                <div class="div-content">
                    <h3 class="div-content-h3"></h3>
                    <p>
                        <span class="content-title">Are you licensed Pharmaceuticals? </span>
                        <ul>
                            <li>Yes, we are licensed Pharmaceuticals. </li>
                        </ul>
                    </p>
                </div>
                <div class="div-content">
                    <h3 class="div-content-h3"></h3>
                    <p>
                        <span class="content-title">Do you sell your products to end users directly? </span>
                        <ul>
                            <li>No, We sell Our products to distributors and resekkers only. We don't sell to end users directly. </li>
                        </ul>
                    </p>
                </div>
                <div class="div-content">
                    <h3 class="div-content-h3"></h3>
                    <p>
                        <span class="content-title">I can not find any supplier of your product. How can i buy your products? </span>
                        <ul>
                            <li>Please email us your contry and which products you want to buy. We will forward you to one of our suppliers. </li>
                        </ul>
                    </p>
                </div>
                <div class="div-content">
                    <h3 class="div-content-h3"></h3>
                    <p>
                        <span class="content-title">How can be a reseller or distributor of your products? </span>
                        <ul>
                            <li>Please contact us about reseller and distributor details.We Will inform you about it. </li>
                        </ul>
                    </p>
                </div>
                <div class="div-content">
                    <h3 class="div-content-h3"></h3>
                    <p>
                        <span class="content-title">Is there a minimum order limit for reseller and distributor? </span>
                        <ul>
                            <li>Yes, there is a minimum order limit for reseller and distributor orders. </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        beforeMount: function () {
        }
    }
</script>

<style scoped>
    .content-main .content .content-title {
        color: #444;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 7px;
        display: block;
    }

    .div-title-main {
        padding-right: 150px;
    }

    .div-img-header {
        background: url("/assets/img/header/page-title.jpg");
        background-position: right;
        background-size: cover;
        background-repeat: no-repeat;
        height: 260px;
        position: relative;
        width: 100%;
    }

    .page-title .breadcrumb {
        text-align: center;
    }

    .page-title .breadcrumb {
        background-color: transparent;
        padding: 0;
    }

    .page-title ol {
        color: #fff;
    }

    .page-title h2 {
        font-size: 48px;
        font-size: 3.2rem;
        font-weight: 800;
        margin: 1em 0 0.23em;
    }

    .page-title h2 {
        color: #fff;
    }

    .page-title .breadcrumb li a {
        color: #fff;
    }

    .page-title .breadcrumb li {
        font-size: 18px;
        font-size: 1.2rem;
        color: #fdc900;
        margin-left: 5px;
    }

    .page-title::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }

    .page-title .breadcrumb > li + li::before {
        font-family: "FontAwesome";
        content: "\f105";
        color: #fff;
        margin-left: 5px;
    }

    .page-title .breadcrumb li a:hover {
        color: #fdc900;
    }

    ul {
        padding-left: 20px;
        line-height: 1.8;
    }

    .div-content ul li {
        list-style: disc;
    }

    .div-content-h3 {
        margin-bottom: 10px;
        font-size: 17px;
        font-weight: bold;
        padding-right: 3px;
        color: #666;
    }

    .address-text p {
        color: #777;
        font-size: 15px;
        padding-right: 7px;
    }

    .address-title i {
        font-size: 20px;
        color: cadetblue;
        vertical-align: baseline;
    }

    .address-title span {
        font-size: 18px;
        font-weight: bold;
        color: #555;
    }

    .btn-info {
        background-color: #2CA8FF;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 17px;
        padding: 8px 35px;
    }

    .btn-div {
        text-align: center;
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
    .input-textarea {
        padding: 11px 15px 11px 11px;
    }

    .content-main .content {
        width: 100%;
        margin: 20px 12%;
        padding: 20px;
    }

        .content-main .content .title {
            font-size: 20px;
            padding-right: 20px;
            padding-top: 20px;
        }

        .content-main .content .div-content p {
            text-align: justify;
            line-height: 2;
            font-size: 15px;
        }

    @media only screen and (min-width: 0px) and (max-width: 640px) {
        .div-title-main {
            padding-right: 20px;
        }

        .div-content ul li {
            font-size: 12px;
        }

        .div-content-h3 {
            font-size: 15px;
        }

        .address-title span {
            font-size: 16px;
        }

        .content-main .content {
            margin: 40px 0%;
        }

            .content-main .content .title {
                font-size: 17px;
            }

            .content-main .content .div-content p {
                text-align: justify;
                line-height: 2;
                font-size: 14px;
            }
    }
    /*محتویات Css  قسمت مدیریت صفحات استاتیک*/
</style>