<template>
    <div>
        <!-- responsive-header -->
        <responsive-header-component></responsive-header-component>
        <!-- responsive-header -->

        <div class="wrapper default">

            <!-- header -->
            <header class="main-header default">
                <headerTop-component></headerTop-component>
                <div class="container">
                    <header-component></header-component>
                </div>
                <menu-component></menu-component>
            </header>
            <!-- header -->
            <!-- main -->
            <main class="page-404">
                <div class="container text-center">
                    <div class="page-404-title">
                        <h1>NOT FOUND!</h1>
                    </div>
                    <div class="page-404-actions">
                        <router-link tag="a" to="/" class="page-404-action page-404-action--primary">صفحه اصلی</router-link>
                    </div>
                    <div class="page-404-image">
                        <img src="/assets/img/404.png">
                    </div>
                </div>
            </main>
            <!-- main -->
            <!-- footer -->
            <footer-component></footer-component>
            <!-- footer -->
        </div>
    </div>
</template>

<script>
    import { initialSite } from "../../module/initialSite";
    export default {
        beforeMount: function () {
            createPageTitle('Error 404');
            initialSite(this);
        },
    }
</script>

<style scoped>
</style>