import { httpPromise } from "./httpUtility"

export const initialSite = function (component) {
   
    if (component.$store.getters.readHomePageContents == null) {
        component.$data.showLoader = true;
        httpPromise(component, '/v1/ContentManagement/Contents/Read', 'get', {},
            response => {
                if (response.data.Value.BaseInfos == null)
                {
                    component.$router.push('/webServiceError');
                    component.$data.showLoader = false;
                }
                component.$store.commit('setHomePageContents', response.data.Value);
                component.$data.showLoader = false;
                initialJs();
                initialMenuJs();
            },
            response => {
                component.$data.showLoader = false;
                window.location.href = '/webServiceError';
            }
        );
    } else {
        initialJs();
        initialMenuJs();
        component.$data.showLoader = false;
    }
}