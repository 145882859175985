import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from './routes';
import { requireAuthPages } from './requireAuthPages'

Vue.use(VueRouter);


export const router = new VueRouter({
    routes: routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {
    var authRequired = requireAuthPages.includes(to.path)
    if (authRequired) {
        var accessToken = localStorage.getItem('accessToken');
        if (accessToken == '' || accessToken == null)
            return next('/login?returnUrl=' + to.path);
    }
    next(true);
})