import 'babel-polyfill'
//==========================================================
import Vue from 'vue';
import { router } from './router/router'
import VeeValidate, { Validator } from 'vee-validate'
import VueResource from 'vue-resource'
import App from './App.vue';
import en from 'vee-validate/dist/locale/en';
//==========================================================
import { store } from './store/store'
import { registerGlobalComponents } from './global-components';
import { registerGlobalFilters } from "./global-filters"
//==========================================================
Vue.use(VueResource);
Vue.use(VeeValidate, {
    classes: true,
    classNames: {
        valid: 'is-valid',
        invalid: 'is-invalid'
    }
});
Validator.localize('en', en);
//==========================================================
registerGlobalComponents();
registerGlobalFilters();
//==========================================================
new Vue({
    router: router ,
    store,
    render: h => h(App),
   
}).$mount('#app');
//==========================================================
